import { useCallback, useContext, useEffect, useState } from "react";
import { WindowManagerContext } from "./WindowManagerProvider";
import { FloatingWindowElement } from "./FloatingWindowElement";
import { MinimizedRestorer } from "./MinimizedRestorer";

export function WindowManager() {
    const manager = useContext(WindowManagerContext);
    const [, inc] = useState(0);
    const update = useCallback(() => inc(prev => prev + 1), []);

    useEffect(() => {
        if (!manager)
            return;

        manager.subscribe(update);

        const fn = (e: MouseEvent) => manager.focusWindow(e);
        window.addEventListener("pointerdown", fn);

        return () => {
            window.removeEventListener("pointerdown", fn);
            manager.unsubscribe(update);
        };
    }, [manager]);

    if (!manager)
        return null;

    const isAnyMinimized = manager.getWindows().some(w => w.isMinimized());

    return (
        <>
            {<MinimizedRestorer show={isAnyMinimized} restore={() => manager.restoreAllMinimized()} />}
            {manager.getWindows().map((win, i) => <FloatingWindowElement instance={win} key={i} />)}
        </>
    );
}
