import { CSSProperties, useCallback, useEffect, useState } from "react";

const DURATION = 220;  // ms

type Props = {
    minimized?: boolean;
    setMinimalized: (value: boolean) => void;
};

export function useMinimizeable({
    minimized = false,
    setMinimalized,
}: Props) {
    const [styles, setStyles] = useState<CSSProperties>({});

    const minimize = useCallback(() => {
        setMinimalized(true);

        setStyles({
            transition: `all ${DURATION / 1000}s ease-out`,
            top: "50vh",
            left: 0,
            transform: "translate(-50%, -50%) scale(0)",
        });
    }, [setMinimalized]);

    const restore = useCallback(() => {
        setMinimalized(false);

        setStyles({
            transitionProperty: "top, left, transform",
            transitionTimingFunction: "ease-out",
            transitionDuration: `${DURATION / 1000}s`,
            transform: "translate(0, 0) scale(1)",
        });

        setTimeout(() => {
            setStyles({});
        }, DURATION);
    }, [setMinimalized]);

    useEffect(() => {
        if (minimized)
            minimize();
        else
            restore();
    }, [minimized]);

    return {
        isMinimized: minimized,
        minimize,
        restore,
        styles,
    };
}
