import { useCallback, useEffect, useRef, useState } from "react";

function calculateNextCoords(
    prev: [number, number],
    startCoords: [number, number],
    e: PointerEvent,
    boundingBox: DOMRect,
    bodyBound: boolean,
): [number, number] {
    let newX = prev[0];
    let newY = prev[1];

    const bodyWidth = document.body.clientWidth;
    const bodyHeight = document.body.clientHeight;
    let tempX = e.clientX + startCoords[0];
    let tempY = e.clientY + startCoords[1];

    // Bounds check
    if (bodyBound) {
        // Left bound
        if (tempX < 0)
            tempX = 0;
        // Right bound
        else if (tempX + boundingBox.width > bodyWidth)
            tempX = bodyWidth - boundingBox.width;

        // Top bound
        if (tempY < 0)
            tempY = 0;
        // Bottom bound
        else if (tempY + boundingBox.height > bodyHeight)
            tempY = bodyHeight - boundingBox.height;

        newX = tempX;
        newY = tempY;
    } else {
        newX = e.clientX + startCoords[0];
        newY = e.clientY + startCoords[1];
    }

    return [newX, newY];
}

type Props = {
    useAltKey?: boolean;
    bodyBound?: boolean;
    updateCoords: (callback: (prev: [number, number]) => [number, number]) => void;
};

export function useDraggable({
    useAltKey = true,
    bodyBound = true,
    updateCoords,
}: Props) {
    const [startCoords, setStartCoords] = useState<[number, number] | null>(null);
    const mouseCoords = useRef<[number, number]>([0, 0]);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);

    const drag = useCallback(() => {
        if (timeoutRef.current !== null)
            clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            if (!elementRef.current)
                return;

            setStartCoords([
                elementRef.current.getBoundingClientRect().left - mouseCoords.current[0],
                elementRef.current.getBoundingClientRect().top - mouseCoords.current[1],
            ]);
            document.body.style.userSelect = "none";
        }, 10);
    }, []);

    const move = useCallback((e: PointerEvent) => {
        mouseCoords.current[0] = e.clientX;
        mouseCoords.current[1] = e.clientY;

        if (startCoords === null || !elementRef.current)
            return;

        const boundingBox = elementRef.current.getBoundingClientRect();
        updateCoords(prev => calculateNextCoords(prev, startCoords, e, boundingBox, bodyBound));
    }, [startCoords, bodyBound, updateCoords]);

    const release = useCallback(() =>{
        setStartCoords(null);
        document.body.style.userSelect = "";

        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    const keyDownEvent = useCallback((e: KeyboardEvent) => {
        if (!useAltKey)
            return;

        if (e.key !== "Alt" || !elementRef.current)
            return;

        const boundingBox = elementRef.current.getBoundingClientRect();
        if (
            // @ts-expect-error - TS doesn't know about clientX
            (e.clientX >= boundingBox.left || e.clientX <= boundingBox.right) &&
            // @ts-expect-error - TS doesn't know about clientY
            (e.clientY >= boundingBox.top || e.clientY <= boundingBox.bottom)
        )
            return;

        drag();
    }, [useAltKey]);

    const keyUpEvent = useCallback((e: KeyboardEvent) => {
        if (!useAltKey)
            return;

        if (e.key === "Alt")
            release();
    }, [useAltKey]);

    useEffect(() => {
        window.addEventListener("keydown", keyDownEvent);
        window.addEventListener("pointermove", move);
        window.addEventListener("pointerup", release);
        window.addEventListener("keyup", keyUpEvent);

        return () => {
            window.removeEventListener("keydown", keyDownEvent);
            window.removeEventListener("pointermove", move);
            window.removeEventListener("pointerup", release);
            window.removeEventListener("keyup", keyUpEvent);
        }
    }, [move, keyDownEvent, keyUpEvent]);

    return {
        isDragging: startCoords !== null,
        drag,
        mouseCoords,
        elementRef,
    };
}
