import { useCallback, useContext, useEffect, useState } from "react";
import { isDev, isProd } from "../../common/utils";
import { FloatingWindow } from "../../../components/FloatingWindows/react/FloatingWindow";
import { QLibClientContext } from "./QLibClientProvider";
import { Badge, Button } from "react-bootstrap";
import { FunctionStatus } from "../../common/types";
import "./debug-tools.scss";
import { PIQ } from "../../core/piq";

export function DebugTools() {
    const client = useContext(QLibClientContext);
    const [, inc] = useState(0);
    const update = useCallback(() => inc(prev => prev + 1), []);

    useEffect(() => {
        if (isProd())
            return;

        if (!client)
            return;

        client.subscribe(update)
        return () => client.unsubscribe(update);
    }, []);

    const queriesCount = client === null ? 0 : client.getQueryCount();
    const queries = client === null ? [] : client.getQueries();

    useEffect(() => {
        if (isProd() || queriesCount === 0)
            return;

        const temp = queries;
        temp.forEach(([, q]) => q.subscribe(update));
        return () => temp.forEach(([, q]) => q.unsubscribe(update));
    }, [queriesCount]);
    
    const activeQueriesCount = queries.filter(([n]) => n > 0).length;
    const fetchingQueriesCount = queries.filter(([, q]) => q.getState().functionStatus === FunctionStatus.Running).length;
    const staleQueriesCount = queries.filter(([, q]) => q.isStale()).length;
    const disabledQueriesCount = queries.filter(([, q]) => !q.getState().enabled).length;
    const pollingQueriesCount = queries.filter(([, q]) => q instanceof PIQ && q.getState().polling !== null).length;

    return isDev() && <FloatingWindow
        title="QLib DebugTools"
        classes={["qlib-debug-tools"]}
        size={[500, 500]}
        minimized={true}
        content={
            <>
                <div className="overview">
                    <Badge bg="secondary">All: {queriesCount}</Badge>
                    <Badge bg="success">Active: {activeQueriesCount}</Badge>
                    <Badge bg="primary">Fetching: {fetchingQueriesCount}</Badge>
                    <Badge bg="warning">Stale: {staleQueriesCount}</Badge>
                    <Badge bg="danger">Paused: {disabledQueriesCount}</Badge>
                    <Badge bg="info">Polling: {pollingQueriesCount}</Badge>
                </div>
                <ul className="queries">
                    {queries.map(([n, q], i) => {
                        return <li key={i}>
                            <div className="name">{q.getName()}</div>
                            <div className="actions">Actions:
                                <Button size="sm" variant="secondary" onClick={() => q.setEnabled(!q.getState().enabled)}>{q.getState().enabled ? "Disable" : "Enable"}</Button>
                                <Button size="sm" variant="secondary" onClick={() => q.refetch()}>Refetch</Button>
                                <Button size="sm" variant="secondary" onClick={() => client?.deleteQuery(q.getKey())}>Delete</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>Active: {String(n > 0)}</li>
                                    <li>Stale: {String(q.isStale())}</li>
                                    <li>Enabled: {String(q.getState().enabled)}</li>
                                    <li>Status: {q.getState().status}</li>
                                    <li>FunctionStatus: {q.getState().functionStatus}</li>
                                    <li>Error: {q.getState().error === null ? "false" : q.getState().error!.toString()}</li>
                                </ul>
                            </div>
                        </li>;
                    })}
                </ul>
            </>
        }
    />
}
