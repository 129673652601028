/*
Beware of Babel transpilation.
If using Babel, classes will be transpiled to ES5 and will not be recognized as classes by instanceof.
*/

class NetworkException extends Error {
    constructor(message?: string, options?: ErrorOptions) {
        super(message, options);
        this.name = this.constructor.name;
    }
}

export {
    NetworkException,
};
