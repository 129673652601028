import { faClose, faMagnifyingGlass, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup } from "react-bootstrap";
import TextInput from 'react-autocomplete-input';
import { FormEvent, useEffect, useRef, useState } from "react";
import "./search-input.scss";

const inputHints = process.env.NODE_ENV !== undefined && process.env.NODE_ENV === "development"
? {
    "": ["A0A0C5PVI1"]
} : {
    "": []
};

type Props = {
    invalid?: boolean;
    stringValue?: string;
    onSubmitString(proteinId: string): void;
    onSubmitFile(file: File): void;
};

export function SearchInput({
    stringValue = "",
    invalid = false,
    onSubmitString,
    onSubmitFile
}: Props) {
    const [inputValue, setInputValue] = useState(stringValue);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => setInputValue(stringValue), [stringValue]);

    function submitString(e: FormEvent) {
        e.preventDefault();
        if (inputValue === "")
            return;
        onSubmitString(inputValue);
    }

    function handleFileSelect(e: FormEvent) {
        e.preventDefault();

        if (!fileInputRef.current)
            return;

        if (fileInputRef.current.files === null)
            return;

        const file = fileInputRef.current.files[0];
        const sizeInKb = file.size / 1024;
        if (sizeInKb > Number(import.meta.env.VITE_UPLOAD_FILE_SIZE_LIMIT)) {
            alert(`File size exceeds the limit of ${import.meta.env.VITE_UPLOAD_FILE_SIZE_LIMIT} KB`);
            return;
        }

        const ext = file.name.split('.').pop();
        if (ext !== "pdb" && ext !== "cif") {
            alert("Allowed file types are PDB and mmCIF (.pdb, .cif).");
            return;
        }

        setInputValue("");
        onSubmitFile(file);
        fileInputRef.current.value = "";
    }

    function clear() {
        if (fileInputRef.current)
            fileInputRef.current.value = "";
        setInputValue("");
    }

    const inputGroupClasses = invalid ? "is-invalid" : "";
    const inputClasses = invalid ? "text-input form-control is-invalid": "text-input form-control";

    return (
        <Form action="" method="POST" className="search-input" onSubmit={submitString}>
            <input type="file" name="file" accept=".pdb, .cif" ref={fileInputRef} onChange={handleFileSelect} />
            <Form.Group>
                <Form.Label htmlFor="search">
                    Search using UniProt, PDB ID, Gene Symbol<br />
                    or<br/>
                    upload mmCIF or PDB file
                </Form.Label>
                <InputGroup className={inputGroupClasses}>
                    {/* <InputGroup.Text onClick={submitString} >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </InputGroup.Text> */}
                    <TextInput
                        className={inputClasses}
                        id="search"
                        name="search"
                        placeholder="e.g. P10632, 1TQN or NLGN1"
                        value={inputValue}
                        onChange={val => setInputValue(val as string)}
                        Component="input"
                        maxOptions={6}
                        options={inputHints}
                        trigger={['']}
                        spacer=""
                    />
                    <InputGroup.Text className={inputValue === "" ? "" : "clear--visible"}>
                        <FontAwesomeIcon icon={faClose} onClick={() => clear()} className="clear" />
                        <FontAwesomeIcon icon={faMagnifyingGlass} onClick={submitString} />
                        <FontAwesomeIcon icon={faUpload} onClick={() => fileInputRef.current?.click()} />
                    </InputGroup.Text>
                </InputGroup>
            </Form.Group>
        </Form>
    );
}
