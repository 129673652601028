import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faFlask, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useRef, useState } from "react";
import "./search-meta-box.scss";
import { QueryProteinContext } from "../../pages/ProteinSearch/ProteinSearch";
import { ExperimentalStructuresModalAttributes } from "../ProteinTable/ProteinTable";
import { getPdbIds } from "../../data/uniProtApiWrapper";
import { useTimer } from "../../lib/hooks/useTimer";
import { SimilarProteinsResponse } from "../../data/dataLoading";

type Props = {
    loading: boolean;
    originalInput: string;
    lastPage: SimilarProteinsResponse | undefined;
    setExperimentalStructuresModalValue: React.Dispatch<React.SetStateAction<ExperimentalStructuresModalAttributes>>;
};

export function SearchMetaBox({
    loading,
    originalInput,
    lastPage,
    setExperimentalStructuresModalValue
}: Props) {
    const [experimentalStructures, setExperimentalStructures] = useState<string[] | null>(null);
    const queryProtein = useContext(QueryProteinContext)
    const lastLoadingState = useRef(false);
    const timer = useTimer();

    useEffect(() => {
        if (loading !== lastLoadingState.current) {
            timer.reset();
            timer.start();
        } else
            timer.stop();

        lastLoadingState.current = loading;

        return () => {
            lastLoadingState.current = false;
        }
    }, [loading]);

    useEffect(() => {
        // Reset on new UniProt ID
        setExperimentalStructures(null);

        // Do not perform on an empty query
        if (queryProtein.uniProtId === "")
            return;

        async function fetch() {
            const promisedStructures = await getPdbIds(queryProtein.uniProtId);
            const structures = promisedStructures.results.map(structure => structure.to);

            setExperimentalStructures(structures);
        }

        fetch();
    }, [queryProtein.uniProtId]);

    let queryString = originalInput;
    if (queryProtein.file !== null)
        queryString = queryProtein.file.name;
    else if (queryProtein.uniProtId !== "") {
        if (originalInput === queryProtein.uniProtId)
            queryString = queryProtein.uniProtId;
        else
            queryString = originalInput + " → " + queryProtein.uniProtId;
    }

    function openExperimentalStructuresModal(event: React.MouseEvent) {
        event.preventDefault();
        setExperimentalStructuresModalValue({
            entryUniProtId: queryProtein.uniProtId,
            experimentalStructures: experimentalStructures as string[],
        });
    }

    // Remember elapsed time
    if (lastPage !== undefined && lastPage.searchTime === 0)
        lastPage.searchTime = timer.time;

    return (
        <ul className="load-info">
            <li>
                <div>Query:<b className="ms-2">{queryString}</b></div>
            </li>

            {queryProtein.file !== null && queryProtein.uniProtId !== "" && (
                <li>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Found matching UniProtID with uploaded file using TM-Score=1.
                            </Tooltip>
                        }
                    >
                        <div>
                            Matching UniProtID: <b>{queryProtein.uniProtId}</b>
                            <FontAwesomeIcon className="ms-2" icon={faInfoCircle} />
                        </div>
                    </OverlayTrigger>
                </li>
            )}

            {queryProtein.name !== "" && (
                <li>
                    <div>Name: <b>{queryProtein.name}</b></div>
                </li>
            )}

            {queryProtein.organism !== "" && (
                <li>
                    <div>Organism: <b>{queryProtein.organism}</b></div>
                </li>
            )}

            {queryProtein.uniProtId !== "" && (
                <li><div>
                    {queryProtein.uniProtId} in <a href={`https://www.uniprot.org/uniprotkb/${queryProtein.uniProtId}/entry`} target="_blank" rel="noreferrer" className="text-decoration-none">
                        UniProt
                        <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            style={{ transform: 'scale(0.6)' }}
                        />
                    </a>

                    {
                        experimentalStructures === null ? (
                            <OverlayTrigger overlay={
                                <Tooltip>
                                    Looking for experimental structures...
                                </Tooltip>
                            }>
                                <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />
                            </OverlayTrigger>
                        ) : (experimentalStructures.length > 0 &&
                            <OverlayTrigger overlay={
                                    <Tooltip>
                                        Experimental structures (PDBe) are present
                                    </Tooltip>
                                }>
                                <Button className="show-3d p-0 ms-1 text-decoration-none" onClick={openExperimentalStructuresModal} variant="link" rel="noreferrer" style={{
                                    transform: 'translateY(-6px)',
                                    display: "inline-block",
                                    height: 21 + 'px',
                                }}>
                                    <FontAwesomeIcon icon={faFlask} /> ({experimentalStructures.length})
                                </Button>
                            </OverlayTrigger>
                        )
                    }
                </div></li>
            )}

            <li><div>
                Search Time:
                {
                    (lastPage?.searchTime === 0 || loading) ? <b className="ms-2">{timer.time.toFixed(2)}s</b> : (
                        <b className="ms-2">{lastPage?.searchTime.toFixed(2)}s</b>
                    )
                }

                {/* {(exactTime === -1 || loading) ? <b className="ms-2">{timer.time.toFixed(2)}s</b> : (
                    <b className="ms-2">{exactTime.toFixed(2)}s</b>
                )} */}

                {/* {
                    timer.time < 0.05
                    ? (
                        loading
                        ? (
                            <Spinner animation="border" variant="secondary" role="status" size="sm" className="ms-2">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : <i><b className="ms-2">Cached</b></i>
                    )
                    : <b className="ms-2">{timer.time.toFixed(2)} s</b>
                } */}
            </div></li>
        </ul>
    );
}
