import { useCallback, useEffect, useRef, useState } from "react";

type Props = {
    step: number;  // ms
};

export function useTimer(props: Props = { step: 10 }) {
    const [running, setRunning] = useState(false);
    const [time, setTime] = useState(0);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const savedTime = useRef(-1);

    const saveTimer = useCallback(() => {
        savedTime.current = performance.now();
    }, []);

    const restoreTime = useCallback(() => {
        if (!running || savedTime.current === -1)
            return;

        const temp = savedTime.current;
        savedTime.current = -1;
        setTime(prev => prev + (performance.now() - temp) / 1000);
    }, [running]);

    useEffect(() => {
        window.addEventListener("blur", saveTimer);
        window.addEventListener("focus", restoreTime);

        return () => {
            window.removeEventListener("blur", saveTimer);
            window.removeEventListener("focus", restoreTime);
        }
    }, [restoreTime]);

    useEffect(() => {

    }, [running]);

    const start = useCallback(() => {
        timer.current = setInterval(() => {
            setTime(prev => prev + props.step / 1000);
        }, props.step);

        setRunning(true);
    }, []);

    const stop = useCallback(() => {
        if (timer.current !== null)
            clearInterval(timer.current);
        
        setRunning(false);
    }, []);

    const reset = useCallback(() => {
        stop();
        setTime(0);
    }, [stop]);

    return {
        start,
        stop,
        reset,
        running,
        time,
    };
}
