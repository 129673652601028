import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QLibClientProvider } from "./lib/query/react/components/QLibClientProvider.tsx";
import { EnvBasedStyles } from "./lib/debug/EnvBasedStyles/EnvBasedStyles.tsx";
import { WindowManagerProvider } from "./lib/components/FloatingWindows/react/WindowManagerProvider.tsx";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <WindowManagerProvider>
            <QLibClientProvider>
                <QueryClientProvider client={queryClient}>
                    <EnvBasedStyles />

                    <App />

                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </QLibClientProvider>
        </WindowManagerProvider>
    </React.StrictMode>,
);
