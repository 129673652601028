// ENUMS
export enum FunctionStatus {
    Idle = "idle",
    Running = "running",
}

export enum Status {
    Pending = "pending",
    Success = "success",
    Error = "error",
}

// TYPES
// Generic

// StrictOmit will complain if we are trying to omit non-existing key
export type StrictOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
// PartialBy sets only given keys to as optional
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type Listener<What> = (what: What) => void;
export type NonEmptyArray<T> = [T, ...Array<T>];
export type HeterogeneousNonEmptyArray<T0, T1> = [T0, ...Array<T1>];

export type Primitive = string | number | boolean;
export type Seconds = number;
export type Miliseconds = number;
export type Datetime = number;

// Generic for queries

export type CacheMeta<State> = {
    validUntil: Datetime;
    state: State;
};

export type QueryKey = Readonly<HeterogeneousNonEmptyArray<string, Primitive>>;

export type BaseQueryFunction<Data> = CallableFunction;
export type PIQFunction<Page, PageParams> = (params: PageParams, ...args: QueryKey) => Promise<Page>;

// Base query

export type BaseQuerySettings = {
    // Defaults to 0
    obsoleteTime: Miliseconds;

    // Hard refreshing in intervals
    // number 0 < x < Infinity
    // 0 is invalid => throws an error
    // Infinity for no interval
    // Defaults to Infinity, which means no interval at all
    refreshInterval: Seconds;

    // Whether to use caching
    // If false, query will be destroyed immediately after zero listeners are detected
    // Defaults to true
    cache: boolean;
    // Infinity for never invalidate
    // Defaults to Infinity
    invalidateCacheInterval: Seconds;

    // 0 for no retry at all
    // Infinite for infinite retries
    // Defaults to 3
    retry: number;
    // How long to wait before retrying
    // Defaults to 0, which means immediate retry
    retryInterval: Miliseconds;

    // Whether to refetch on mount (Called manually by React)
    // Defaults to true
    refetchOnMount: boolean;
    // Whether to refetch on window focus
    // Defaults to true
    refetchOnWindowFocus: boolean;
};

export type BaseQuerySettingsComplete =
    BaseQuerySettings &
    {
        // The query will fetch if set to true
        // If false, the query will be paused
        enabled?: boolean;
    };

export type BaseQueryOptionsRequired<Data> = {
    fn: BaseQueryFunction<Data>;
}

// Base query state
export type BaseQueryStateInternal = {
    functionStatus: FunctionStatus;
    error: null | Error;
    enabled: boolean;
}

export type BaseQueryState =
    BaseQueryStateInternal &
    {
        status: Status;
    };

// (Standard) Query
// Not implemented yet

// (Pooling) Infinite Query
export type PIQSettings<Page> =
    BaseQuerySettingsComplete &
    {
        pollPageOnWindowFocus: () => boolean;
        pollPageInterval: ((lastPage: Page) => Miliseconds) | Miliseconds;
        pollingCondition: (lastPage: Page) => boolean;  // true will poll, false will not poll
        selectData: (data: Page[]) => Page[];
    };

export type PIQOptionsRequired<Page, PageParams> = {
    fn: PIQFunction<Page, PageParams>;
    initialPageParams: PageParams;
    getNextPageParams: (lastPage: Page, allPages: Page[], lastPageParam: PageParams) => PageParams | null;
};

export type PIQOptionsClass<Page, PageParams> =
    PIQSettings<Page> &
    PIQOptionsRequired<Page, PageParams>;

export type PIQOptions<Page, PageParams> = 
    Partial<PIQSettings<Page>> &
    PIQOptionsRequired<Page, PageParams>;

export type PIQState<Page, PageParams> =
    BaseQueryState &
    {
        pages: Page[];
        pageParams: PageParams[];
        polling: Page | null;
        isFetchingNext: boolean;
    };

export type PIQProps<Page, PageParams> =
    PIQOptions<Page, PageParams> &
    {
        key: QueryKey;
    };
