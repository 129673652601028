import { Listener } from "../common/types";

export class Observer<What> {
    private listeners: Array<Listener<What>> = [];

    public subscribe(listener: Listener<What>) {
        this.listeners.push(listener);
    }

    public unsubscribe(listener: Listener<What>) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    protected notifyAll(what: What) {
        this.listeners.forEach(l => l(what));
    }

    protected clearListeners() {
        this.listeners = [];
    }

    protected getListeners() {
        return this.listeners;
    }

    protected hasListener(listener: Listener<What>) {
        return this.listeners.includes(listener);
    }
}
