import { Observer } from "./observer";
import { Window } from "./window";

export class WindowManager extends Observer {
    protected windows: Window[] = [];
    protected zIndexStart: number = 9900;

    constructor () {
        super();
    }

    public addWindow(window: Window) {
        this.windows.push(window);
        window.setZIndex(this.zIndexStart);
        this.zIndexStart++;
        this.notifyAll();

        return this.windows.length - 1;
    }

    public removeWindow(window: Window) {
        const index = this.windows.indexOf(window);
        if (index !== -1)
            this.windows.splice(index, 1);

        this.notifyAll();
    }

    public removeWindowByIndex(index: number) {
        if (index >= 0 && index < this.windows.length)
            this.windows.splice(index, 1);

        this.notifyAll();
    }

    public getWindows() {
        return this.windows;
    }

    public focusWindow(e: MouseEvent) {
        const windowsUnderCursor = [];
        
        for (const win of this.windows) {
            if (win.getElement() === null)
                continue;

            const bb = win.getElement()!.getBoundingClientRect();
            if (
                e.clientX >= bb.x &&
                e.clientX <= bb.x + bb.width &&
                e.clientY >= bb.y &&
                e.clientY <= bb.y + bb.height
            )
                windowsUnderCursor.push(win);
        }
        if (windowsUnderCursor.length === 0)
            return;

        const globalTopmost = this.windows.reduce((acc, cur) => cur.getZIndex() > acc.getZIndex() ? cur : acc, this.windows[0]);
        const underCursorTopmost = windowsUnderCursor.reduce((acc, cur) => cur.getZIndex() > acc.getZIndex() ? cur : acc);

        const windowsWithZIndexBetween = this.windows.filter(win => win.getZIndex() > underCursorTopmost.getZIndex());

        // If the window under the cursor is the topmost window, do nothing
        if (underCursorTopmost.getZIndex() === globalTopmost.getZIndex())
            return;

        // If the window under the cursor is not the topmost window, bring it to the top
        underCursorTopmost.setZIndex(globalTopmost.getZIndex());
        // And decrease the zIndex of all windows with zIndex between the topmost window and the window under the cursor
        windowsWithZIndexBetween.forEach(win => win.setZIndex(win.getZIndex() - 1));
    }

    public update() {
        this.notifyAll();
    }

    public restoreAllMinimized() {
        this.windows.forEach(win => {
            if (win.isMinimized())
                win.setMinimized(false);
        });

        this.notifyAll();
    }
}
