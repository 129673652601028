import { debug, warning } from "../../logger/core";
import { FunctionStatus, PIQOptions, PIQOptionsClass, PIQState, QueryKey, Status } from "../common/types";
import { BaseQuery } from "./baseQuery";

export class PIQ<
    Page,
    PageParams,
> extends BaseQuery<
    Page,
    PIQState<Page, PageParams>,
    PIQOptionsClass<Page, PageParams>
> {
    constructor(key: QueryKey, options: PIQOptions<Page, PageParams>) {
        super(key, {
            pollingCondition: () => false,
            pollPageInterval: 5000,
            pollPageOnWindowFocus: true,
            ...options,
        });
    }

    protected getInitState(): PIQState<Page, PageParams> {
        return {
            pages: [],
            pageParams: [],
            polling: null,
            functionStatus: FunctionStatus.Idle,
            error: null,
            enabled: false,
            status: Status.Pending,
            isFetchingNext: false,
        };
    }

    public isFirstRun(): boolean {
        return this.state.pageParams.length === 0
            && this.state.pages.length === this.state.pageParams.length;
    }

    protected async fetchSinglePage(pageParams: PageParams) {
        let response = null;
        let retryCount = 0;

        outer:
        while(this.options.retry === Infinity || retryCount <= this.options.retry) {
            // Ensure query is still not disabled as this can run really long
            if (!this.state.enabled) {
                break outer;
            }

            if (retryCount === 0)
                debug(`Trying query "${this.getName()}/${JSON.stringify(pageParams)}" (${retryCount + 1})...`, "QLIB");
            else {
                debug(`Retrying query "${this.getName()}/${JSON.stringify(pageParams)}" in ${this.options.retryInterval} (${retryCount + 1})...`, "QLIB");
                await new Promise(resolve => setTimeout(resolve, this.options.retryInterval));
            }

            debug(`   Running query "${this.getName()}/${JSON.stringify(pageParams)}" (${1})...`, "QLIB");
            try {
                response = await this.options.fn(pageParams, ...this.getKey());
            } catch (e) {
                console.log(`   Error in query "${this.getName()}/${JSON.stringify(pageParams)}" (1)!`);
                retryCount++;
                response = null;
                this.state.error = e as Error;
                continue outer;
            }

            let pollAgain = this.options.pollingCondition(response);
            if (pollAgain) {
                this.state.polling = response;
                this.dispatch();
            }

            let pollingCount = 1;
            while (!pollAgain) {
                // Ensure query is still not disabled as this can run really long
                if (!this.state.enabled) {
                    break outer;
                }

                const interval = this.options.pollPageInterval instanceof Function
                    ? this.options.pollPageInterval(response)
                    : this.options.pollPageInterval;
                debug(`   Polling query "${this.getName()}/${JSON.stringify(pageParams)}" in ${interval} (${pollingCount + 1})...`, "QLIB");
                await new Promise(resolve => setTimeout(resolve, interval));

                try {
                    response = await this.options.fn(pageParams, ...this.getKey());
                } catch (e) {
                    debug(`   Error in query "${this.getName()}/${JSON.stringify(pageParams)}" (${pollingCount + 1})!`, "QLIB");
                    retryCount++;
                    response = null;
                    this.state.error = e as Error;
                    continue outer;
                }

                this.state.polling = response;
                this.dispatch();
                pollAgain = this.options.pollingCondition(response);
                pollingCount++;
            }

            // Success, break all loops
            break outer;
        }

        if (response === null) {
            debug(`Query "${this.getName()}/${JSON.stringify(pageParams)}" failed after ${retryCount} retries.`, "QLIB");
        } else {
            if (this.state.enabled) {
                debug(`Query "${this.getName()}/${JSON.stringify(pageParams)}" succeeded.`, "QLIB");
                this.state.pages.push(response);
                this.updateCacheTs();
                this.freshUntil = Date.now() + this.options.obsoleteTime;
                this.refreshAt = Date.now() + this.options.refreshInterval * 1000;
            } else {
                debug(`Query "${this.getName()}/${JSON.stringify(pageParams)}" was disabled while it was running.`, "QLIB");
                this.freshUntil = Infinity;
                this.refreshAt = Infinity;
            }
       }

        this.state.polling = null;
        this.dispatch();
    }

    protected _refetch(clear: boolean = true): void {
        if (!this.state.enabled) {
            warning(`Query ${this.getName()} is not enabled!`, "QLIB");
            return;
        }
        if (this.state.functionStatus === FunctionStatus.Running) {
            warning(`Attempt to refetch query ${this.getName()} while it is already running!`, "QLIB");
            return;
        }

        if (clear) this.clear(false);
        this.state.functionStatus = FunctionStatus.Running;
        if (this.isFirstRun()) this.state.pageParams.push(this.options.initialPageParams);
        this.dispatch();

        (async () => {
            let i = clear ? 0 : this.state.pages.length;
            while (i < this.state.pageParams.length) {
                await this.fetchSinglePage(this.state.pageParams[i]);
                i++;
            }

            this.state.functionStatus = FunctionStatus.Idle;
            this.dispatch();
        })();
    }

    public refetch(): void {
        this._refetch();
    }

    public getState(): PIQState<Page, PageParams> {
        const status = this.getStatus();

        return {
            ...this.state,
            status: status,
            isFetchingNext: status === Status.Pending
                && this.state.pageParams.length > 1
                && this.state.pages.length > 0
                && this.state.pages.length !== this.state.pageParams.length
        };
    }

    protected getStatus(): Status {
        if (this.state.error)
            return Status.Error;

        // Disabled and not all pages fetched
        if (
            !this.state.enabled &&
            this.state.pageParams.length !== this.state.pages.length
        )
            return Status.Pending;
        
        // Enabled, but no pages fetched and pageParams is not empty
        if (this.state.pages.length === 0)
            return Status.Pending;
        
        // Nothing more to fetch
        if (this.state.pages.length === this.state.pageParams.length)
            return Status.Success;

        return Status.Pending;
    }

    public fetchNext(): void {
        if (!this.state.enabled) {
            warning(`Query ${this.getName()} is not enabled!`, "QLIB");
            return;
        }
        if (this.state.functionStatus === FunctionStatus.Running) {
            warning(`Attempt to refetch query ${this.getName()} while it is already running!`, "QLIB");
            return;
        }

        const i = this.state.pages.length - 1;
        const nextPageParams = this.options.getNextPageParams(
            this.state.pages[i],
            this.state.pages,
            this.state.pageParams[i]
        );

        if (nextPageParams === null)
            throw new Error(`Query ${this.getName()} has reached the end!`);

        this.state.pageParams.push(nextPageParams);
        // Not necessary to dispatch here, as _refetch will dispatch
        this._refetch(false);
    }

    public setInitialPageParams(pageParams: PageParams) {
        this.options.initialPageParams = pageParams;
    }

    public clear(dispatch: boolean = true): void {
        this.state.functionStatus = FunctionStatus.Idle;
        this.state.error = null;
        this.state.pages = [];
        this.freshUntil = Infinity;

        if (dispatch) this.dispatch();
    }
}
