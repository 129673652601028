import { createContext, useEffect, useRef } from "react";
import { QueryManager } from "../../core/manager";
import { DebugTools } from "./DebugTools";

export const QLibClientContext = createContext<QueryManager | null>(null);

type ClientProviderProps = {
    children: JSX.Element[] | JSX.Element;
};

export function QLibClientProvider({ children }: ClientProviderProps) {
    const client = useRef(new QueryManager());

    useEffect(() => {
        const ref = client.current;
        ref.setup();
        return () => ref.cleanup();
    }, []);

    return (
        <QLibClientContext.Provider value={client.current}>
            <DebugTools />
            {children}
        </QLibClientContext.Provider>
    );
}
