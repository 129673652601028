import { createContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import 'react-autocomplete-input/dist/bundle.css';
import { useQuery } from "@tanstack/react-query";
import "./protein-search.scss";
import { calcEstimatedSearchTime} from "../../common/utils";
import SearchInput from "../../components/SearchInput";
import ProteinVisualizer from "../../components/ProteinVisualizer";
import CustomSpinner from "../../components/CustomSpinner";
import ProteinTable from "../../components/ProteinTable";
import LoadMoreButton from "../../components/LoadMoreButton";
import { Representation } from "../../common/enums";
import { fetchQueryProteinMetadata } from "../../data/dataLoading";
import { ExperimentalStructuresModalAttributes } from "../../components/ProteinTable/ProteinTable";
import SearchMetaBox from "../../components/SearchMetaBox";
import ExperimentalStructuresModal from "../../components/ExperimentalStructuresModal";
import useProteinSearch from "../../common/proteinSearch";
import { ErrorOverlay } from "../../components/ErrorOverlay/ErrorOverlay";
import { NetworkException } from "../../lib/query/core/exceptions";

export const DEFAULT_LIMIT = 50;
export const MAX_LIMIT = 1_000;

export type QueryProtein = {
    uniProtId: string;
    name: string;
    organism: string;
    file: File | null;
};

export type onLoadMoreHeader = (count: number) => void;

export const QueryProteinContext = createContext<QueryProtein>({
    uniProtId: "",
    name: "",
    organism: "",
    file: null,
});

function renderNoResults(
    isQueryValidUniProtID: boolean,
    isInAlphaFoldDB: boolean,
    isInvalidFile: boolean,
) {
    let text = "";
    if (isQueryValidUniProtID) {
        text = isInAlphaFoldDB ?
            "This protein is not yet included in the AlphaFind database." :
            "This protein is not yet included in the AlphaFold database.";
    } else {
        text = "This protein is unknown to AlphaFind or it does not exist.";
    }

    if (isInvalidFile) {
        text = "Invalid file format.";
    }

    return (
        <div className="text-center disabled mt-5">
            <i style={{ fontSize: 1.5 + 'rem' }}>
                {text}
            </i>
        </div>
    );
}

type Props = {
    file: File | null;
    setFile: (file: File | null) => void;
};

export function ProteinSearch({ file, setFile }: Props) {
    const state = useProteinSearch({ file });
    const [inputValue, setInputValue] = useState<string>(state.proteinQueryString);
    const [experimentalStructuresModalValue, setExperimentalStructuresModalValue] = useState<ExperimentalStructuresModalAttributes>(null);

    const queryProteinMetadataQ = useQuery({
        queryKey: ["queryProteinMetadata", state.uniProtId],
        queryFn: () => fetchQueryProteinMetadata(state.uniProtId!),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: state.uniProtId !== "",
        retry: false,
    });

    // Update value in the form when the query string changes
    useEffect(() => {
        if (state.proteinQueryString === "" || state.proteinQueryString.toLowerCase() === inputValue)
            return;

        setInputValue(state.proteinQueryString);
    }, [state.proteinQueryString]);

    function onSubmitString(value: string) {
        setFile(null);
        setInputValue(value);
        state.searchWithString(value);
    }

    function onSubmitFile(f: File) {
        setFile(null);
        setInputValue("");
        state.searchWithFile(f);
    }

    // Query protein object
    const queryProtein: QueryProtein = {
        uniProtId: state.uniProtId,
        file: state.proteinQueryFile,
        name: queryProteinMetadataQ.data?.name ?? "",
        organism: queryProteinMetadataQ.data?.organism ?? "",
    };

    // Shortcut for rendering
    let queueInfo = undefined;
    if (state.isQueue && (
            (state.loadingMoreCount <= 50 && state.queuePosition! >= 2) ||
            (state.loadingMoreCount > 50 && state.queuePosition! >= 1)
    ))
        queueInfo = {
            position: state.queuePosition!,
            readyUrl: window.location.href,
            estimatedTime: calcEstimatedSearchTime(state.loadingMoreCount) * state.queuePosition!,
        };

    return (
        <QueryProteinContext.Provider value={queryProtein}>
            {state.isError && <ErrorOverlay isNetwork={state.error instanceof NetworkException} />}

            <ExperimentalStructuresModal
                attrs={experimentalStructuresModalValue}
                onHide={() => setExperimentalStructuresModalValue(null)}
            />
            <article>
                <section>
                    <Container>
                        <Row className="search-info">
                            <Col xs="12" xl="4" className="mb-4 d-flex align-items-center">
                                <SearchInput
                                    invalid={!state.isFetching && state.isEmptyResults}
                                    stringValue={inputValue}
                                    onSubmitString={onSubmitString}
                                    onSubmitFile={onSubmitFile}
                                />
                            </Col>
                            <Col xs="12" md="6" xl="4" className="mb-4 mb-sm-0">
                                <div>
                                    {(state.uniProtId !== "" || queryProtein.file !== null) && <ProteinVisualizer
                                        proteins={queryProtein.file === null ? [queryProtein.uniProtId] : [queryProtein.file]}
                                        height={300}
                                        defaultRepresentation={Representation.Cartoon}
                                        spin={true}
                                    />}
                                </div>
                            </Col>
                            <Col xs="12" md="6" xl="4" className="query-info-container">
                                <SearchMetaBox
                                    loading={state.isFetching}
                                    originalInput={state.proteinQueryString}
                                    lastPage={state.lastPage}
                                    setExperimentalStructuresModalValue={setExperimentalStructuresModalValue}
                                />
                            </Col>
                        </Row>

                        {(state.isFetching && !state.isFetchingNext) && <CustomSpinner queue={queueInfo} />}

                        {!state.isEmptyResults && (<>
                            <ProteinTable data={state.results} setExperimentalStructuresModalValue={setExperimentalStructuresModalValue} />
                            <LoadMoreButton loadMoreFn={state.loadMore} isLoading={state.isFetchingNext} queue={queueInfo} currentDataLength={state.results.length} />
                        </>)}

                        {(!state.isFetching && state.isEmptyResults) &&
                        renderNoResults(state.isValueValidUniProtID, state.isInAlphaFoldDB, state.isInvalidFile)}
                    </Container>
                </section>
                {/* <footer></footer> */}
            </article>
        </QueryProteinContext.Provider>
    );
}
