import { createContext, useRef } from "react";
import { WindowManager as WMClass } from "../core/manager";
import { WindowManager } from "./WindowManager";

export const WindowManagerContext = createContext<WMClass | null>(null);

type Props = {
    children: JSX.Element | JSX.Element[];
};

export function WindowManagerProvider({ children }: Props) {
    const manager = useRef(new WMClass());

    return (
        <WindowManagerContext.Provider value={manager.current}>
            <WindowManager />

            {children}
        </WindowManagerContext.Provider>
    );
}
