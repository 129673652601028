import { Record } from "../components/ProteinTable/ProteinTable";
import { amIRunningInProductionEnv, isStageDisaEnv } from "../common/utils";
import { callUniProtMapping, canBePdbID } from "./pdbToUniProtMapping";
import { ResponseMapping, getPdbIds, mapGeneToUniProt } from "./uniProtApiWrapper";
import { CallAPIParams } from "../common/types";
import { info, warning, debug } from "../lib/logger/core";
import React from "react";
import { NetworkException } from "../lib/query/core/exceptions";

export type SimilarProteinsResponse = {
    data: Record[];
    queuePosition: number | null;
    searchTime: number;
    isValueValidUniProtID: boolean;
    isInAlphaFoldDB: boolean;
    isInvalidFile: boolean;

    matchingUniProtId: string;
    digest: string;

};

type APIRawResponseRecord = {
    object_id: string;
    rmsd: number;
    tm_score: number;
    aligned_percentage: number;
    sequence_aligned_percentage: number;
};

type APIRawResponse = {
    results?: APIRawResponseRecord[];
    queue_position?: number;
    search_time?: number;

    code?: number;
    message?: string;
    digest?: string;
};

/** 
 * Actual call to the backend
 */
export async function callAPI(params: CallAPIParams): Promise<APIRawResponse> {
    let baseApiUrl = import.meta.env.VITE_API_LOCALHOST_URL;
    if (isStageDisaEnv())
        baseApiUrl = import.meta.env.VITE_API_STAGE_URL;
    if (amIRunningInProductionEnv())
        baseApiUrl = import.meta.env.VITE_API_PRODUCTION_URL;
    baseApiUrl += "/search";

    const apiUrl = new URL(baseApiUrl);
    apiUrl.searchParams.set("limit", params.limit.toString());
    apiUrl.searchParams.set("offset", params.offset.toString());
    const ri: RequestInit = {};
    const formData = new FormData();

    if (params.proteinQueryFile === null)
        apiUrl.searchParams.append("query", params.proteinQueryString);
    else {
        formData.append("file", params.proteinQueryFile);
        ri.method = "POST";
        ri.body = formData
    }

    // This line can throw an error. It is handled by the query library.
    let res: Response;
    try {
        res = await fetch(apiUrl.toString(), ri);
    } catch(e: unknown) {
        // Network error! See MDN docs why is this error network related
        throw new NetworkException();
    }

    if (!res.ok && res.status !== 400)
        throw new Error(`${res.status} ${res.statusText}`);
    
    return await res.json()
}

async function isValidUniProtID(value: string): Promise<boolean> {
    let res;
    try {
        res = await fetch(`https://rest.uniprot.org/uniprotkb/${value}.json`);
    } catch (e) {
        info(`Value ${value} is not a valid UniProt ID.`, "DATA_LOADING");
        return false;
    }

    info(`Value ${value} is a valid UniProt ID.`, "DATA_LOADING");

    return res.status === 200;
}

async function isInAlphaFoldDB(value: string): Promise<boolean> {
    let res;
    try {
        res = await fetch(`https://www.alphafold.ebi.ac.uk/api/prediction/${value}?key=AIzaSyCeurAJz7ZGjPQUtEaerUkBZ3TaBkXrY94`);
    } catch (e) {
        info(`Value ${value} is not in the AlphaFold DB.`, "DATA_LOADING");
        return false;
    }

    info(`Value ${value} is in the AlphaFold DB.`, "DATA_LOADING");
    
    return res.status === 200;
}

async function tryMapping(queryValue: string): Promise<[string, boolean, boolean]> {
    let uniProtId = "";

    // can the input be a pdb id? -> make a call to uniProt mapping
    if (canBePdbID(queryValue))
        uniProtId = await callUniProtMapping(queryValue)

    // if that failed, the input can be a gene symbol
    if (uniProtId === "")
        // uniProtId = await getGeneMapping(queryValue)
        uniProtId = await mapGeneToUniProt(queryValue);

    // if that failed, the input may still be valid UniProt ID but it is not in our database
    let isStringValidUniProtID = false;
    let isUniProtIdInAlphaFoldDB = false;
    if (uniProtId === "") {
        isStringValidUniProtID = await isValidUniProtID(queryValue);

        // if it is valid, we can check whether it is in the AlphaFold DB
        if (isStringValidUniProtID)
            isUniProtIdInAlphaFoldDB = await isInAlphaFoldDB(queryValue);

        return [uniProtId, isStringValidUniProtID, isUniProtIdInAlphaFoldDB];
    }

    // Mapping was successful
    // console.log(`Changing PDB ID/Gene symbol: ${queryValue} to UniProtID: ${uniProtId}`);
    info(`Changing PDB ID/Gene symbol: ${queryValue} to UniProtID: ${uniProtId}`, "DATA_LOADING");

    return [uniProtId, isStringValidUniProtID, isUniProtIdInAlphaFoldDB];
}

export async function searchSimilarProteins(params: CallAPIParams, startTime?: number): Promise<SimilarProteinsResponse> {
    const startTimeCurrent = startTime ?? performance.now();

    const call = await callAPI(params);

    const response: SimilarProteinsResponse = {
        data: [],
        queuePosition: null,
        searchTime: 0,
        isValueValidUniProtID: true,
        isInAlphaFoldDB: true,
        isInvalidFile: false,

        matchingUniProtId: "",
        digest: "",
    };

    // Error handling
    if (call.code !== undefined) {
        info(`Error ${call.code} in the API call: ${call.message}`, "DATA_LOADING");
        response.isInvalidFile = true;
        return response;
    }

    // No matter what, there is waiting queue
    if (call.queue_position !== undefined) {
        response.queuePosition = call.queue_position;
        response.matchingUniProtId = await isValidUniProtID(params.proteinQueryString) ? params.proteinQueryString : "";
        response.digest = call.digest ?? "";
        return response;
    }

    // Really, really should not happen unless something is with the API
    if (call.results === undefined) throw new Error("Internal error. Results are undefined.");

    // So far, so good
    if (call.results.length > 0) {
        response.matchingUniProtId = await isValidUniProtID(params.proteinQueryString) ? params.proteinQueryString : "";

        if (params.proteinQueryFile !== null) {
            // Remember the digest for the next call
            if (call.digest !== undefined)
                response.digest = call.digest;

            // If we have a file, we can try to match the protein to the file by TM-Score=1
            const matchToFile = call.results.find(item => item.tm_score === 1);
            if (matchToFile !== undefined)
                response.matchingUniProtId = matchToFile.object_id;
        }

        response.data = await fetchMetadata(call.results);
        // Lines below measure exact time for a single run of this function
        // Currently, it is not used as it is not usable for pooling queries
        // const apiSearchTime = call.search_time ?? 0;
        // response.searchTime = apiSearchTime === 0 ? (performance.now() - startTimeCurrent) / 1000 : apiSearchTime;
        response.searchTime = 0;
        return response;
    }

    const mappedValue = await tryMapping(params.proteinQueryString);
    if (mappedValue[0] === "") {
        response.isValueValidUniProtID = mappedValue[1];
        response.isInAlphaFoldDB = mappedValue[2];
        return response;
    }
    
    // Need to call the API again with the mapped value
    return searchSimilarProteins({
        ...params,
        proteinQueryString: mappedValue[0],
    }, startTimeCurrent);
}

/**
 * Fetches single metadata for an object using the AlphaFold API based on UniProtID.
 * @param object - The API result object.
 * @returns A promise that resolves to the prepared row containing the fetched metadata.
 */
async function fetchSingleMetadata(object: APIRawResponseRecord): Promise<Record> {
    let res;
    try {
        res = await fetch(`https://www.alphafold.ebi.ac.uk/api/prediction/${object.object_id}?key=AIzaSyCeurAJz7ZGjPQUtEaerUkBZ3TaBkXrY94`);
    } catch (e) {
        warning(`Metadata cannot be fetched for ${object.object_id}.`, "DATA_LOADING");

        return {
            rmsd: object.rmsd,
            tmScore: object.tm_score,
            alignedLength: object.aligned_percentage,
            identicalAAs: object.sequence_aligned_percentage,
            experimentalStructuresExists: false,
            uniProtId: object.object_id,
            name: null,
            organism: null,
            isReviewed: false,
            taxId: 0,
            sequence: "",
            gene: "",
            experimentalStructures: null,
        };
    }
    
    if (!res.ok) {
        warning(`Metadata cannot be fetched for "${object.object_id}".`, "DATA_LOADING");

        return {
            rmsd: object.rmsd,
            tmScore: object.tm_score,
            alignedLength: object.aligned_percentage,
            identicalAAs: object.sequence_aligned_percentage,
            experimentalStructuresExists: false,
            uniProtId: object.object_id,
            name: null,
            organism: null,
            isReviewed: false,
            taxId: 0,
            sequence: "",
            gene: "",
            experimentalStructures: null,
        };
    }

    const json = await res.json();

    const record: Record = {
        rmsd: object.rmsd,
        tmScore: object.tm_score,
        alignedLength: object.aligned_percentage,
        identicalAAs: object.sequence_aligned_percentage,
        experimentalStructuresExists: false,
        organism: json['0']['organismScientificName'],
        uniProtId: object.object_id,
        name: json['0']['uniprotDescription'],
        isReviewed: json['0']['isReviewed'],
        taxId: json['0']['taxId'],
        sequence: json['0']['uniprotSequence'],
        gene: json['0']['gene'],
        experimentalStructures: null,
    };

    return record;
}

/**
 * Fetches metadata for a list of objects.
 * @param objects - The list of objects to fetch metadata for.
 * @returns A promise that resolves to an array of prepared rows.
 */
export async function fetchMetadata(objects: APIRawResponseRecord[]): Promise<Record[]> {
    const requests = objects.map(o => fetchSingleMetadata(o));

    const promises = await Promise.all(requests);

    return promises;
}

/** 
 * Returns the metadata for objectId used in as the query object.
 */
export async function fetchQueryProteinMetadata(objectId: string) {
    const data: APIRawResponseRecord = {
        object_id: objectId,
        // Rest is unused in this context
        rmsd: 0,
        tm_score: 0,
        aligned_percentage: 0,
        sequence_aligned_percentage: 0,
    };

    return fetchSingleMetadata(data);
}

type FetchExperimentalStructuresParams = {
    current: {
        query: string,
        limit: number,
        running: boolean,
    };
};

export async function fetchExperimentalStructures(
    data: Record[],
    setData: React.Dispatch<React.SetStateAction<Record[]>>,
    params: FetchExperimentalStructuresParams,
) {
    const query = params.current.query;
    const limit = params.current.limit;

    if (query === "" || limit === 0)
        return;

    if (params.current.running) {
        debug(`Experimental Structures: The fetch is already running (params: ${query}, ${limit}).`, "DATA_LOADING");
        return;
    }

    debug(`Experimental Structures: Init (params: ${query}, ${limit}).`, "DATA_LOADING");

    const BATCH_SIZE: number = Number(import.meta.env.VITE_EXPERIMENTAL_DATA_FETCH_BATCH_SIZE);
    const PAUSE: number = Number(import.meta.env.VITE_EXPERIMENTAL_DATA_FETCH_PAUSE);
    const dataCopy = [...data];

    params.current.running = true;

    let i = 0;
    let batch: [number, Promise<ResponseMapping>][] = [];
    while (i < dataCopy.length) {
        // Prevent race condition
        if (params.current.query !== query || params.current.limit !== limit) {
            debug(`Experimental Structures: Cancelling (params: ${query}, ${limit}).`, "DATA_LOADING");
            params.current.running = false;
            return;
        }

        if (dataCopy[i].experimentalStructures === null)
            batch.push([i, getPdbIds(dataCopy[i].uniProtId)]);

        if (batch.length === BATCH_SIZE) {
            debug(`Experimental Structures: Fetching batch ${Math.floor(i / BATCH_SIZE)} (params: ${query}, ${limit}).`, "DATA_LOADING");
            const promises = batch.map(item => item[1]);
            const results = await Promise.all(promises);

            results.forEach((result, index) => {
                const pdbIds = result.results.map(item => item.to);
                dataCopy[batch[index][0]].experimentalStructures = pdbIds;
            });

            // Prevent race condition
            if (params.current.query !== query || params.current.limit !== limit) {
                debug(`Experimental Structures: Cancelling (params: ${query}, ${limit}).`, "DATA_LOADING");
                params.current.running = false;
                return;
            }

            // Make React sensitive to change
            // When passing the same array the change is not detected as
            // the reference is the same
            setData([
                ...dataCopy,
            ]);
            batch = [];
            await new Promise(resolve => setTimeout(resolve, PAUSE));
        }

        i++;
    }

    debug("Experimental Structures: Done!", "DATA_LOADING");
}
