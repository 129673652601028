import { useCallback, useEffect, useRef, useState } from "react";

// Do not allow the window to be resized below these values no matter what
// (sanity check)
const ABS_MIN_WIDTH = 200;
const ABS_MIN_HEIGHT = 200;

function calcSize(
    prev: [number, number],
    cursorRelPos: [number, number],
    e: PointerEvent,
    boundingBox: DOMRect,
    bounds: {
        minWidth: number,
        minHeight: number,
        maxWidth: number,
        maxHeight: number,
    },
): [number, number] {
    let newWidth = prev[0];
    let newHeight = prev[1];

    let tempWidth = e.clientX - boundingBox.left;
    let tempHeight = e.clientY - boundingBox.top;

    // Size bounds check
    if (tempWidth < bounds.minWidth)
        tempWidth = bounds.minWidth;
    else if (tempWidth > bounds.maxWidth)
        tempWidth = bounds.maxWidth;

    if (tempHeight < bounds.minHeight)
        tempHeight = bounds.minHeight;
    else if (tempHeight > bounds.maxHeight)
        tempHeight = bounds.maxHeight;

    newWidth = tempWidth;
    newHeight = tempHeight;

    return [newWidth, newHeight];
}

type Props = {
    minSize?: [number, number];
    maxSize?: [number, number];
    resizeable?: boolean;
    updateSize: (callback: (prev: [number, number]) => [number, number]) => void;
};

export function useResizeable({
    minSize = [200, 200],
    maxSize = [500, 500],
    resizeable = true,
    updateSize,
}: Props) {
    const [cursorRelPos, setCursorRelPos] = useState<[number, number] | null>(null);
    const mouseCoords = useRef<[number, number]>([0, 0]);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);

    const resize = useCallback(() => {
        if (!resizeable)
            return;

        if (timeoutRef.current !== null)
            clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            if (elementRef.current === null)
                return;

            setCursorRelPos([
                elementRef.current.getBoundingClientRect().left - mouseCoords.current[0],
                elementRef.current.getBoundingClientRect().top - mouseCoords.current[1],
            ]);
            document.body.style.userSelect = "none";
        }, 10);
    }, [resizeable]);

    const move = useCallback((e: PointerEvent) => {
        mouseCoords.current[0] = e.clientX;
        mouseCoords.current[1] = e.clientY

        if (!resizeable || cursorRelPos === null || elementRef.current === null)
            return;

        updateSize(prev => calcSize(prev, cursorRelPos, e,
            elementRef.current!.getBoundingClientRect(), {
            minWidth: Math.max(minSize[0], ABS_MIN_WIDTH),
            minHeight: Math.max(minSize[1], ABS_MIN_HEIGHT),
            maxWidth: maxSize[0],
            maxHeight: maxSize[1],
        }));
    }, [resizeable, cursorRelPos, updateSize, minSize[0], minSize[1], maxSize[0], maxSize[1]]);

    const release = useCallback(() => {
        if (!resizeable)
            return;

        setCursorRelPos(null);
        document.body.style.userSelect = "";

        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, [resizeable]);

    useEffect(() => {
        if (!resizeable)
            return;

        window.addEventListener("pointermove", move);
        window.addEventListener("pointerup", release);

        return () => {
            window.removeEventListener("pointermove", move);
            window.removeEventListener("pointerup", release);
        }
    }, [move, resizeable]);

    return {
        resize,
        isResizing: cursorRelPos !== null,
        mouseCoords,
        elementRef,
    };
}
