
import { useDraggable } from "./hooks/draggable";
import { useResizeable } from "./hooks/resizeable";
import "./floating-window.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useMinimizeable } from "./hooks/minimizeable";
import { CSSProperties, MouseEvent, PointerEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Window } from "../core/window";
import { WindowManagerContext } from "./WindowManagerProvider";

type Props = {
    instance: Window,
};

export function FloatingWindowElement({ instance }: Props) {
    const manager = useContext(WindowManagerContext);

    const [, inc] = useState(0);
    const update = useCallback(() => inc(prev => prev + 1), []);

    const ref = useRef<HTMLDivElement | null>(null);

    const draggable = useDraggable({
        useAltKey: false,
        bodyBound: instance.getBodyBound(),
        // To ensure "this" is bind and not undefined
        updateCoords: (coords) => instance.updateCoords(coords),
    });
    const resizeable = useResizeable({
        resizeable: instance.getAllowResize(),
        minSize: instance.getMinSize(),
        maxSize: instance.getMaxSize(),
        // To ensure "this" is bind and not undefined
        updateSize: (size) => instance.updateSize(size),
    });
    const minimizeable = useMinimizeable({
        minimized: instance.isMinimized(),
        setMinimalized: value => {
            instance.setMinimized(value)
            if (manager)
                manager.update();
        },
    });

    useEffect(() => {
        if (ref.current !== null)
            instance.setElement(ref.current);

        instance.subscribe(update);
        return () => instance.unsubscribe(update);
    }, [instance]);

    const close = useCallback(() => {
        if (!manager)
            return;
        
        instance.close();
        manager.removeWindow(instance);
    }, [manager, instance]);

    function drag(e: PointerEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.button === 0)
            draggable.drag();
    }

    function minimize(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.button === 0)
            minimizeable.minimize();
    }

    function resize(e: PointerEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.button === 0)
            resizeable.resize();
    }

    // const contentPreprocessed: string | JSX.Element = (
    //     typeof instance.getContent() === "object"
    //     ? (<pre>{JSON.stringify(instance.getContent(), null, 4)}</pre>)
    //     : instance.getContent() as string
    // );

    const style: CSSProperties = {
        left: `${instance.getCoords()[0]}px`,
        top: `${instance.getCoords()[1]}px`,
        width: `${instance.getSize()[0]}px`,
        height: `${instance.getSize()[1]}px`,
        cursor: draggable.isDragging ? "grabbing" : "auto",
        zIndex: instance.getZIndex(),

        ...minimizeable.styles,
    };

    let classes = ["floating-window"];
    if (instance.getClasses() !== undefined && instance.getClasses()!.length > 0)
        classes = classes.concat(instance.getClasses()!);

    return (
        <>
            <div
                className={classes.join(" ")}
                style={style}
                ref={(e) => {ref.current = e; draggable.elementRef.current = e; resizeable.elementRef.current = e;}}
            >
                <div className="floating-window__header" onPointerDown={drag}>
                    <div className="floating-window__title">{instance.getTitle()}</div>
                    <div className="icons">
                        <div className="icon" onClick={minimize}>
                            <FontAwesomeIcon icon={faMinus} />
                        </div>
                        <div className="icon" onClick={() => close()}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                </div>
                <div className="floating-window__content">
                    {instance.getContent()}
                </div>
                <div className="floating-window__footer">{instance.getFooter()}</div>
                {instance.getAllowResize() && <div className="resizer" onPointerDown={resize}></div>}
            </div>
        </>
    );
}
