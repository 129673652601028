import { BaseQueryOptionsRequired, BaseQuerySettings, BaseQueryState, BaseQueryStateInternal, CacheMeta, FunctionStatus, Miliseconds, QueryKey, Status } from "../common/types";
import { Observer } from "./observer";

export abstract class BaseQuery<
    Data,
    State extends BaseQueryStateInternal,
    Options extends BaseQuerySettings & BaseQueryOptionsRequired<Data>,
> extends Observer<BaseQueryState> {
    private key: QueryKey;
    private cache: CacheMeta<unknown> | null = null;

    protected options: Options;
    private name: string;

    protected freshUntil: Miliseconds;
    protected refreshAt: Miliseconds;
    protected state: State;

    constructor(key: QueryKey, options: Partial<BaseQuerySettings> & BaseQueryOptionsRequired<Data>) {
        super();

        if (options.refreshInterval === 0)
            throw new Error("Refresh interval option cannot be 0!");

        if (key.length === 0)
            throw new Error("Key cannot be empty!");

        this.key = key;
        this.name = `${JSON.stringify(key, null).replaceAll(/"/g, '')}`;

        this.options = {
            obsoleteTime: 0,
            refreshInterval: Infinity,
            cache: true,
            invalidateCacheInterval: Infinity,
            retry: 3,
            retryInterval: 0,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            ...options,  // Kinda not the best way as any param can be passed but required for children classes
        } as Options;
        
        this.state = this.getInitState();

        // Set data as obsolete as default
        this.freshUntil = Date.now() - 9999;
        this.refreshAt = Date.now() - 9999;
    }

    protected updateCacheTs() {
        if (this.options.cache === false)
            return;

        this.cache = {
            validUntil: Date.now() + this.options.invalidateCacheInterval * 1000,
            state: null,
        };
    }

    public isCacheValid() {
        if (this.cache === null)
            return false;

        return this.cache.validUntil >= Date.now();
    }

    public invalidateCache() {
        if (this.options.cache === false)
            throw new Error(`Query ${this.getName()} does not have cache enabled!`);

        this.cache = {
            validUntil: 0,
            state: null,
        };
    }

    public setEnabled(enabled: boolean) {
        this.state.enabled = enabled;

        if (enabled && this.isFirstRun()) this.refetch();
    }

    public getState() {
        return {
            ...this.state,
            status: this.getStatus(),
        };
    }

    protected dispatch() {
        this.notifyAll(this.getState());
    }

    /**
     * Updates the properties of the current options with the provided partial options.
     * 
     * @param props - Is copied at the start and then modified (not modified in place)
     */
    public updateProps(props: Partial<Options>) {
        this.options = {
            ...this.options,
            ...props,
        };
    }

    public getOptions() {
        return {...this.options};
    }

    public isStale() {
        return this.state.functionStatus !== FunctionStatus.Running &&
            this.freshUntil < Date.now();
    }

    public shouldBeRefetched() {
        return this.state.enabled &&
            this.state.functionStatus !== FunctionStatus.Running &&
            this.refreshAt < Date.now() &&
            this.options.refreshInterval !== Infinity &&
            this.options.refreshInterval > 0;
    }

    public isEnabled() {
        return this.state.enabled;
    }

    public getName() {
        return this.name;
    }

    public getKey() {
        return this.key;
    }

    protected abstract getInitState(): State;
    protected abstract getStatus(): Status;
    public abstract isFirstRun(): boolean;
    public abstract refetch(): void;
    public abstract clear(dispatch: boolean): void;
}
