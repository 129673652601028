import { BrowserRouter, Route } from "react-router-dom";
import CustomSwitch from "./components/CustomSwitch";
import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";
import ProteinSearch from "./pages/ProteinSearch";
import NotFound from "./pages/NotFound";
import { isProdDisaEnv, isStageDisaEnv } from "./common/utils";
import "./app.scss";
import LandingPage from "./pages/LandingPage";
import { useState } from "react";

export default function App() {
    const [file, setFile] = useState<File | null>(null);

    let basename = "/";
    if (isStageDisaEnv())
        basename = import.meta.env.VITE_STAGING_URL_PREFIX;
    if (isProdDisaEnv())
        basename = import.meta.env.VITE_PRODUCTION_URL_PREFIX;

    return (
        <BrowserRouter basename={basename}>
            <div className="overlay__content">
                <MainHeader />
                {/* <aside></aside> */}
                {/* <navbar></navbar> */}
                <div className="d-flex flex-column main-container">
                    <main>
                        <CustomSwitch>
                            <Route path="/" element={<LandingPage setFile={setFile} />} />
                            <Route path="/search" element={<ProteinSearch file={file} setFile={setFile} />}></Route>

                            <Route path="*" element={<NotFound />}></Route>
                        </CustomSwitch>
                    </main>
                    <MainFooter />
                </div>
            </div>
        </BrowserRouter>
    );
}
