import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FunctionStatus, PIQProps, Status } from "../common/types";
import { QLibClientContext } from "./components/QLibClientProvider";
import { PIQ } from "../core/piq";
import { info } from "../../logger/core";

export function usePIQ<
    Page,
    PageParams,
>(props: PIQProps<Page, PageParams>) {
    const client = useContext(QLibClientContext);
    const query = useRef<PIQ<Page, PageParams> | null>(null);

    const [, inc] = useState(0);
    const update = useCallback(() => inc(prev => prev + 1), []);

    const keyStr = JSON.stringify(props.key);
    const propsStr = JSON.stringify(props,
        (key, value) => (key === "enabled" || key === "key" ? undefined : value));

    useEffect(() => {
        if (!client) {
            query.current = null;
            update();
            return;
        }
        const q = client.getPIQ(props.key, props);

        q.subscribe(update);
        client.subscribeQuery(props.key);
        query.current = q;

        let rerender = true;

        if (!q.isFirstRun() && q.isStale() && q.getOptions().refetchOnMount) {
            info(`(ON MOUNT) Refetching query "${q.getName()}"...`, "QLIB");
            rerender = false;
            q.refetch();
        }

        // When query already exists
        if (rerender)
            update();

        return () => {
            q.unsubscribe(update);
            client.unsubscribeQuery(props.key);
            query.current = null;
        };
    }, [client, keyStr]);

    // Handle enabled prop
    // (enabled is not watched by updateProps on purpose)
    useEffect(() => {
        if (!query.current || props.enabled === undefined)
            return;

        query.current.setEnabled(props.enabled);
    }, [keyStr, props.enabled]);

    // Handle/watch other props for change
    useEffect(() => {
        if (!query.current)
            return;

        query.current.updateProps(props);
    }, [
        keyStr, propsStr, props.fn,
        props.getNextPageParams, props.pollPageInterval, props.pollPageOnWindowFocus, props.pollingCondition, props.selectData,
    ]);

    const state = query.current?.getState();
    // If undefined, it means the query is not yet initialized
    // so we need to provide defaults
    return {
        refetch: () => query.current?.refetch(),
        fetchNext: () => query.current?.fetchNext(),
        invalidateCache: () => query.current?.invalidateCache(),
        pages: state?.pages ?? [],
        pageParams: state?.pageParams ?? [],
        polling: state?.polling ?? null,
        error: state?.error ?? null,
        functionStatus: state?.functionStatus ?? FunctionStatus.Idle,
        status: state?.status ?? Status.Pending,
        isFetchingNext: state?.isFetchingNext ?? false,
    };
}
