import { debug, info } from "../../logger/core";
import { BaseQueryOptionsRequired, BaseQuerySettings, BaseQueryStateInternal, Listener, PIQOptions, QueryKey } from "../common/types";
import { BaseQuery } from "./baseQuery";
import { Observer } from "./observer";
import { PIQ } from "./piq";

export class QueryManager extends Observer<number> {
    private queries: Map<
        string,
        [number, BaseQuery<unknown, BaseQueryStateInternal, BaseQuerySettings & BaseQueryOptionsRequired<unknown>>]
    > = new Map();
    private interval: NodeJS.Timeout | null;

    public constructor() {
        super();

        this.queries = new Map();
        this.interval = null;
    }

    public setup() {
        debug("Setting up query manager.", "QLIB");
        this.interval = this.setupInterval();
        this.setupHandlers();
    }

    private intervalCallback() {
        this.queries.forEach((tuple, key) => {
            const q = tuple[1];

            // CACHE
            // No subscribers, query is stored in cache
            if (tuple[0] === 0) {
                // Cache is not valid, delete query
                if (!q.getOptions().cache || !q.isCacheValid()) {
                    this.queries.delete(key);
                    return;
                }
            }

            // DATA FRESHNESS
            if (q.isStale()) {
                this.notifyAll(this.queries.size);
            }

            // Auto refetch in intervals
            if (q.shouldBeRefetched()) {
                info(`Refreshing query "${q.getName()}"...`, "QLIB");
                q.refetch();
            }
        });

        this.notifyAll(this.queries.size);
    }

    private setupInterval() {
        // Single interval takes care of all queries
        if (this.interval !== null) clearInterval(this.interval);
        return setInterval(() => this.intervalCallback(), 1000);
    }

    private onTabFocus(e: FocusEvent) {
        this.queries.forEach(tuple => {
            const q = tuple[1];

            if (tuple[0] === 0)
                return;

            if (!q.isFirstRun() && q.isStale() && q.getOptions().refetchOnWindowFocus) {
                info(`(TAB FOCUS) Refetching query "${q.getName()}"...`, "QLIB");
                q.refetch();
            }
        });
    }

    public handleEvent(e: Event) {
        switch (e.type) {
            case "focus":
                this.onTabFocus(e as FocusEvent);
                break;
            // Safely exit function
            default:
                break;
        }
    }

    private setupHandlers() {
        window.addEventListener("focus", this, false);
    }

    private removeHandlers() {
        window.removeEventListener("focus", this, false);
    }

    public getQuery(key: QueryKey) {
        throw new Error("Method not implemented.");
    }

    public getPIQ<Page, PageParams>(key: QueryKey, options: PIQOptions<Page, PageParams>): PIQ<Page, PageParams> {
        const keyStr = JSON.stringify(key);

        if (this.queries.has(keyStr)) {
            debug(`Loading query ${key} from cache.`, "QLIB");
            return (this.queries.get(keyStr)![1] as unknown) as PIQ<Page, PageParams>;
        }

        // Query does not exist, create it
        debug(`Creating query ${key}...`, "QLIB");
        const q = new PIQ<Page, PageParams>(key, options);
        this.queries.set(keyStr, [0, q]);

        // Let every listener know about the new query
        this.notifyAll(this.queries.size);

        return q;
    }

    public subscribeQuery(key: QueryKey) {
        const keyStr = JSON.stringify(key);

        if (!this.queries.has(keyStr))
            throw new Error(`Query "${key[0]}" does not exist!`);
            // return;

        const [count, q] = this.queries.get(keyStr)!;
        this.queries.set(keyStr, [count + 1, q]);

        this.notifyAll(this.queries.size);
    }

    public unsubscribeQuery(key: QueryKey) {
        const keyStr = JSON.stringify(key);

        if (!this.queries.has(keyStr))
            // throw new Error(`Query "${key[0]}" does not exist!`);
            return;

        const [count, q] = this.queries.get(keyStr)!;
        if (count > 0)
            this.queries.set(keyStr, [count - 1, q]);

        this.notifyAll(this.queries.size);
    }

    public cleanup() {
        debug("Cleaning up query manager.", "QLIB");
        if (this.interval !== null) clearInterval(this.interval);
        this.removeHandlers();
    }

    public getQueryCount() {
        return this.queries.size;
    }

    public getQueries() {
        return Array.from(this.queries.values());
    }
    
    public subscribe(listener: Listener<number>): void {
        super.subscribe(listener);
        listener(this.queries.size);
    }

    public deleteQuery(key: QueryKey) {
        const keyStr = JSON.stringify(key);

        if (this.queries.has(keyStr))
            this.queries.delete(keyStr);
        else throw new Error(`Query "${key}" does not exist!`);
    }
}
