import { Col, Container, Row } from "react-bootstrap";
import ExampleVisualizations from "../../components/ExampleVisualizations";
import SearchInput from "../../components/SearchInput";
import { useNavigate } from "react-router-dom";

type Props = {
    setFile: (file: File | null) => void;
};

export function LandingPage({ setFile }: Props) {
    const navigate = useNavigate();

    function onSubmitString(value: string): void {
        navigate(`/search?q=${value}`);
    }

    function onSubmitFile(file: File): void {
        setFile(file);
        navigate("/search");
    }

    return (
        <article>
            <section>
                <Container>
                    <Row>
                        <Col xs="12" className="d-flex align-items-center mx-auto my-5 py-2">
                            <SearchInput onSubmitString={onSubmitString} onSubmitFile={onSubmitFile} />
                        </Col>
                    </Row>
                    <ExampleVisualizations />
                </Container>
            </section>
        </article>
    );
}
