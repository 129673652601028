import { useContext } from "react";
import { QueryProteinContext } from "../../pages/ProteinSearch/ProteinSearch";

type Props = {
    filteredCount: number;
    totalCount: number
};

export function FilterInfo({ filteredCount, totalCount }: Props) {
    const queryProtein = useContext(QueryProteinContext);

    const query = queryProtein.file ? queryProtein.file.name : queryProtein.uniProtId;

    return (
        <div className="disabled">
            Most similar proteins to <i>{query}</i> (showing {filteredCount} filtered out of {totalCount})
        </div>
    );
}
