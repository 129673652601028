import "./error-overlay.scss";
import { Button } from "react-bootstrap";

type Props = {
    isNetwork?: boolean;
    message?: string;
};

export function ErrorOverlay({ message, isNetwork = false }: Props) {
    return (
        <div className="error-overlay">
            <div className="error-overlay__content">
                <h3>An error occured.</h3>
                <p style={{maxWidth: "600px", textAlign: "left"}} className="mt-5">
                    Possible cause:
                </p>
                <ul style={{maxWidth: "600px", textAlign: "left"}}>
                    {
                        isNetwork
                        ? <li>You may be disconnected from the network.</li>
                        : (
                            <>
                                <li>Server may be not be responding or the response is invalid,</li>
                                <li>Server encountered an internal error.</li>
                            </>
                        )
                    }
                </ul>
                <p className="mt-5 fs-5">
                    {message ?? "You can reload the page or try again later."}<br />
                </p>
                <Button variant="outline-dark" onClick={() => window.location.reload()} className="d-block mx-auto">Reload Page</Button>
                <p className="mt-5 fs-5">
                    <small>
                        Contact: <a href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}>{import.meta.env.VITE_CONTACT_EMAIL}</a>
                    </small>
                </p>
            </div>
        </div>
    );
}
