import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type RestorerProps = {
    restore: () => void;
    show: boolean;
};

export function MinimizedRestorer({ restore, show }: RestorerProps) {
    const classes = 
        show
        ? "floating-window__restorer"
        : "floating-window__restorer minimized";

    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="tooltip-disabled">
                Restore all minimized windows
            </Tooltip>
        }>
            <div
                className={classes}
                onClick={restore}
            >
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
        </OverlayTrigger>
    );
}
