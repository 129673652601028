import { useEffect, useRef, useState} from "react";
import { callAPI, searchSimilarProteins, SimilarProteinsResponse } from "../data/dataLoading";
import { useDocumentTitle, useUrlParams } from "./utils";
import { useNavigate } from "react-router-dom";
import { usePIQ } from "../lib/query/react/usePIQ";
import { Status } from "../lib/query/common/types";
import { Record } from "../components/ProteinTable/ProteinTable";
import { DEFAULT_LIMIT } from "../pages/ProteinSearch/ProteinSearch";

function selectPages(
    pages: SimilarProteinsResponse[],
    limit: number
) {
    let selectedPages: Record[] = [];
    let i = 0;
    while (selectedPages.length < limit) {
        if (i >= pages.length)
            break;

        selectedPages = selectedPages.concat(pages[i].data.flatMap(p => p));
        i++;
    }

    return selectedPages;
}

type Props = {
    file: File | null;
};

export default function useProteinSearch({ file }: Props) {
    const [urlParams, setUrlParams] = useUrlParams();
    // Below will be set to empty string in "init", otherwise will
    // it always be either a non-empty string or a File
    const [userQuery, setUserQuery] = useState<string | File>(file ?? urlParams.searchQuery);
    const [initialLimit, setInitialLimit] = useState(urlParams.limit);
    const navigate = useNavigate();
    const matchingUniProtId = useRef<string>("");
    const digest = useRef<string>("");

    /*
     * Types:
     * - native valid UniProtID provided by the user
     * - UniProtID mapped from use input (gene symbol, PDB id)
     * - digest of the uploaded file
     * - file itself if digest has not been set yet
     * 
     * These conditions are used to determine what will be sent to API as query.
     * They ensure not mapping to UniProtID will be done twice
     * and also that the file will be uploaded only once -> using digest from now on.
     */
    // If the userQuery is string -> check if mapped to UniProtID and use the best
    // Otherwise, file will be uploaded unless digest (of the file from API) is set
    const activeProteinQueryString =
        typeof userQuery === "string"
        ? (matchingUniProtId.current === "" ? userQuery : matchingUniProtId.current)
        : digest.current;
    // If the userQuery is string -> use the string as the query and NO file (null)
    // Otherwise, if the digest is set, use the digest
    // If not digest, use the file itself
    const activeProteinQueryFile =
        typeof userQuery === "string" || digest.current !== ""
        ? null : userQuery;

    const q = usePIQ({
        key: [
            "data",
            typeof userQuery === "string" ? userQuery : userQuery.name,
        ],
        fn: (param) => searchSimilarProteins({
            proteinQueryString: activeProteinQueryString,
            proteinQueryFile: activeProteinQueryFile,
            limit: param.limit,
            offset: param.offset,
        }),
        initialPageParams: { offset: 0, limit: initialLimit },
        getNextPageParams: (_, allPages) => {
            const count = allPages.flatMap(p => p.data).length;
            return {
                offset: count,
                limit: urlParams.limit - count,
            };
        },
        enabled: typeof userQuery !== "string" || userQuery !== "",
        pollPageInterval: (lastPage) => {
            return lastPage.queuePosition! <= 5 ? 3000 : 6000;
        },
        pollingCondition: lastPage => lastPage.queuePosition === null,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    /*
     * Set the document title to the user query.
     */
    const title = typeof userQuery === "string" ? [userQuery, null] as const : ["", userQuery] as const;
    useDocumentTitle(title[0], title[1]);

    const results = q.pages.flatMap(page => page.data);
    const lastPage = q.pages.length === 0 ? undefined : q.pages[q.pages.length - 1];

    /**
     * Main entrypoint for the search.
     */
    function searchWithString(value: string) {
        const currentQueryString = typeof userQuery === "string" ? userQuery : "";
        value = value.toUpperCase().replace(/\s/g, "");

        // Prevent search if user submits the same query or empty string
        if (value === "" || value === currentQueryString)
            return;
        
        setUrlParams(value, null);
    }

    function searchWithFile(file: File) {
        digest.current = "";
        matchingUniProtId.current = "";
        setInitialLimit(DEFAULT_LIMIT);
        setUserQuery(file);
        setUrlParams(null, null);
    }

    /**
     * Entrypoint for loading more results (pressing the button)
     */
    function loadMore(count: number) {
        setUrlParams(urlParams.searchQuery, urlParams.limit + count);
    }

    useEffect(() => {
        if (q.pages.length > 0 && urlParams.limit > results.length) {
            q.fetchNext();
        }
    }, [urlParams.limit, results.length]);

    /** 
     * Responsible of handling URL parameters.
     * 
     * First use case is when the user uses URl with defined params -> automatic search
     * is performed.
     * Second use case is when the URL params change (e.g. user changes the URL manually)
     * e.g., browser back/forward buttons
     */
    useEffect(() => {
        // If no QUERY or FILE is defined, redirect to the main page
        if (urlParams.searchQuery === "" && typeof userQuery === "string") {
            navigate("/");
            return;
        }

        // Perform search IFF user wants to search with a string
        // AND the value is different from the current (stringish) query
        // otherwise the file is already set (using a function above) and
        // just reset the limit and uniProtId
        if (
            (urlParams.searchQuery !== "" && typeof userQuery !== "string") ||
            (urlParams.searchQuery !== userQuery && typeof userQuery === "string")
        )
            setUserQuery(urlParams.searchQuery);
        setInitialLimit(urlParams.limit);
        matchingUniProtId.current = "";
    }, [urlParams.searchQuery]);

    useEffect(() => {
        if (lastPage === undefined || lastPage.matchingUniProtId === "")
            return;

        if (matchingUniProtId.current !== "")
            return;

        matchingUniProtId.current = lastPage.matchingUniProtId;
    }, [lastPage?.matchingUniProtId]);

    useEffect(() => {
        if (lastPage === undefined || lastPage.digest === "")
            return;

        digest.current = lastPage.digest;
    }, [lastPage?.digest]);

    // If uploaded file has a matching UniProtID
    // (using TM-Score=1), prefetch the results
    // by dummy calling the API
    if (
        results.length > 0 &&
        typeof userQuery !== "string" &&
        matchingUniProtId.current !== ""
    ) {
        // We do not really care about the results here
        callAPI({
            proteinQueryString: matchingUniProtId.current,
            proteinQueryFile: null,
            limit: 50,
            offset: 0,
        });
    }

    return {
        isFetching: q.status === Status.Pending,
        isQueue: q.polling !== null,
        queuePosition: q.polling === null ? null : q.polling.queuePosition,
        isEmptyResults: results.length === 0,
        isFetchingNext: q.isFetchingNext,
        loadingMoreCount: urlParams.limit - results.length,
        isError: q.error !== null,
        error: q.error,
        
        results: selectPages(q.pages, urlParams.limit),
        lastPage: lastPage,

        searchWithString,
        searchWithFile,
        loadMore,

        uniProtId: matchingUniProtId.current || lastPage?.matchingUniProtId || "",
        proteinQueryString: typeof userQuery === "string" ? userQuery : "",
        proteinQueryFile: typeof userQuery !== "string" ? userQuery : null,
        isValueValidUniProtID: lastPage?.isValueValidUniProtID ?? true,
        isInAlphaFoldDB: lastPage?.isInAlphaFoldDB ?? true,
        isInvalidFile: lastPage?.isInvalidFile ?? false,
    };
}
